import { styled, useMediaQuery } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import NextLink from 'next/link';
import React, { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TabLink } from './Links/Link';
import { postToApi } from '../lib/api';
import '../lib/i18n';

const PreAuthLinksWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
  },
}));

const LeftBordered = styled(Box)(({ theme }) => ({
  borderLeft: `2px solid ${theme.palette.text.secondary}`,
  marginLeft: theme.spacing(1.5),
  paddingLeft: theme.spacing(1.5),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
}));

/** Not a true Link, actually a Box with a pointer style. */
const StyledLinkBox = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
}));

const MenuDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1, 1.5),
}));

interface Props {
  logInHref: string;
  logOutHref?: string;
  onLogOut?: (event: React.MouseEvent<HTMLElement>) => Promise<void> | void;
  signUpHref?: string;
  menuItems?: ((onClose: () => void) => JSX.Element)[];
  user?: {
    name: string;
  };
}

const getInitials = (name: string): string => {
  if (name) {
    if (name.includes(' ')) {
      return name
        .split(' ')
        .map((namePart: string) => (namePart.length > 0 ? namePart[0].toUpperCase() : ''))
        .join('');
    }
    return name.charAt(0);
  }
  return '';
};

type AccountDropdownSubComponents = {
  PreAuthLinks: React.FC<PreAuthLinksProps>;
  AuthenticatedDropdown: React.FC<AuthenticatedDropdownProps>;
  MenuLink: React.FC<MenuLinkProps>;
  LogoutLink: React.FC<LogoutLinkProps>;
};

export const AccountDropdown: React.FC<Props> & AccountDropdownSubComponents = ({
  logInHref,
  logOutHref,
  onLogOut,
  signUpHref,
  menuItems,
  user,
}) => {
  return (
    <>
      {user ? (
        <AccountDropdown.AuthenticatedDropdown
          user={user}
          menuItems={menuItems}
          logOutHref={logOutHref}
          onLogOut={onLogOut}
        />
      ) : (
        <AccountDropdown.PreAuthLinks logInHref={logInHref} signUpHref={signUpHref} />
      )}
    </>
  );
};

type PreAuthLinksProps = {
  logInHref: string;
  signUpHref?: string;
};

const AccountDropdownPreAuthLinks = ({ logInHref, signUpHref }: PreAuthLinksProps) => {
  const { t } = useTranslation('accountDropdown');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <PreAuthLinksWrapper>
      <TabLink href={logInHref} text={t('signIn', 'Sign in')} />
      {signUpHref && !isMobile && (
        <LeftBordered>
          <TabLink href={signUpHref} text={t('signUp', 'Sign up')} />
        </LeftBordered>
      )}
    </PreAuthLinksWrapper>
  );
};

type MenuLinkProps = {
  href: string;
  onClick?: MouseEventHandler<HTMLLIElement> | (() => Record<string, unknown>);
  children: string;
};

const AccountDropdownMenuLink = ({ href, onClick = () => {}, children }: MenuLinkProps) => {
  return (
    <NextLink href={href} passHref legacyBehavior>
      <StyledLink underline="hover" href={href}>
        <MenuItem onClick={onClick}>{children}</MenuItem>
      </StyledLink>
    </NextLink>
  );
};

type LogoutLinkProps = {
  onClick?: MouseEventHandler<HTMLLIElement> | (() => Record<string, unknown>);
  children: string;
};

const AccountDropdownLogoutLink = ({ onClick = () => {}, children }: LogoutLinkProps) => {
  return (
    <StyledLinkBox>
      <MenuItem onClick={onClick}>{children}</MenuItem>
    </StyledLinkBox>
  );
};

type AuthenticatedDropdownProps = {
  logOutHref?: string;
  onLogOut?: (event: React.MouseEvent<HTMLElement>) => Promise<void> | void;
  menuItems?: ((onClose: () => void) => JSX.Element)[];
  user: {
    name: string;
  };
};

const AccountDropdownAuthenticatedDropdown = ({
  user,
  menuItems,
  logOutHref,
  onLogOut,
}: AuthenticatedDropdownProps) => {
  const { t } = useTranslation('accountDropdown');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dropdownOpen = Boolean(anchorEl);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogOutHandler = async (event: React.MouseEvent<HTMLElement>) => {
    if (onLogOut) await onLogOut(event);
    await postToApi<void>('/auth/logOut', {}, { rawData: true });
    handleClose();
  };

  return (
    <>
      <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleButtonClick}>
        <Avatar>{getInitials(user.name)}</Avatar>
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={dropdownOpen}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {menuItems && menuItems.map((item) => item(handleClose))}
        {menuItems && menuItems.length > 0 && <MenuDivider />}
        {logOutHref ? (
          <StyledLink
            href={logOutHref}
            onClick={(e) => {
              void onLogOutHandler(e);
            }}
          >
            {t('logOut', 'Log out')}
          </StyledLink>
        ) : (
          <StyledLinkBox
            onClick={(e) => {
              void onLogOutHandler(e);
            }}
          >
            {t('logOut', 'Log out')}
          </StyledLinkBox>
        )}
      </Menu>
    </>
  );
};

AccountDropdown.PreAuthLinks = AccountDropdownPreAuthLinks;
AccountDropdown.MenuLink = AccountDropdownMenuLink;
AccountDropdown.LogoutLink = AccountDropdownLogoutLink;
AccountDropdown.AuthenticatedDropdown = AccountDropdownAuthenticatedDropdown;

export default AccountDropdown;
