import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import common from '../../public/locales/en/common.json';
import footer from '../../public/locales/en/footer.json';
import form from '../../public/locales/en/form.json';

export const resources = {
  en: {
    common,
    form,
    footer,
  },
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatFn = (value?: any, format?: string, lng?: string) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  format === 'float'
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Intl.NumberFormat(lng, { maximumFractionDigits: 2 }).format(value)
    : value;

void use(initReactI18next).init({
  lng: 'en',
  ns: ['common', 'footer', 'form'],
  resources,
  interpolation: {
    format: formatFn,
  },
});
