import { logAnalyticsEvent } from '@lib/analyticsUtils';
import { AdminUser } from '@lib/utils';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { AccountDropdown } from 'common/src/components/AccountDropdown';
import { CompanyLogo } from 'common/src/components/CompanyLogo';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

export const MOBILE_HEADER_HEIGHT = 45;
export const HEADER_HEIGHT = 65;

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: MOBILE_HEADER_HEIGHT,
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    height: HEADER_HEIGHT,
  },
  position: 'fixed',
  width: '100%',
  zIndex: 2,
}));

interface HeaderContentProps {
  user?: AdminUser;
}

const HeaderContent: React.FC<HeaderContentProps> = ({ user }) => {
  const { t } = useTranslation(['common']);
  const router = useRouter();

  return (
    <Wrapper>
      <CompanyLogo size="small" href="/" />
      {user && (
        <AccountDropdown
          logInHref="/login"
          onLogOut={async () => {
            logAnalyticsEvent({ feature: 'Authentication', component: 'Logout', action: 'Click' });
            await router.push('/');
          }}
          menuItems={[
            () => (
              <AccountDropdown.MenuLink
                href="mailto:recruitmentsupport@outcomes4me.com"
                key="helpLink"
                onClick={() =>
                  logAnalyticsEvent({
                    feature: 'AccountDropdown',
                    component: 'Help',
                    action: 'Click',
                  })
                }
              >
                {t('help', 'Help')}
              </AccountDropdown.MenuLink>
            ),
            () => (
              <AccountDropdown.MenuLink
                href="mailto:recruitmentsupport@outcomes4me.com"
                key="contactUsLink"
                onClick={() =>
                  logAnalyticsEvent({
                    feature: 'AccountDropdown',
                    component: 'ContactUs',
                    action: 'Click',
                  })
                }
              >
                {t('contactUs', 'Contact us')}
              </AccountDropdown.MenuLink>
            ),
          ]}
          user={user}
        />
      )}
    </Wrapper>
  );
};

export default HeaderContent;
