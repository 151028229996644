type TextColorType =
  | 'inherit'
  | 'initial'
  | 'textPrimary'
  | 'textSecondary'
  | 'primary'
  | 'secondary'
  | 'error'
  | undefined;
type EmphasisColorType = {
  high: TextColorType;
  medium: TextColorType;
};

export const EmphasisColorMap: EmphasisColorType = {
  high: 'textPrimary',
  medium: 'textSecondary',
};
