import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Image from 'next/image';
import NextLink from 'next/link';
import React from 'react';

import { H1 } from './typography/H1';
import { LogoSubtitle } from './typography/legacy/Text';

const SIZES = {
  xsmall: { width: 66, height: 24 },
  small: { width: 160, height: 24 },
  medium: { width: 200, height: 30 },
  large: { width: 291, height: 42 },
} as const;

const StyledLink = styled(Link)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0),
  },
}));

const LogoWrapper = styled(Box)(() => ({
  textAlign: 'center',
}));

const StyledImage = styled(Image)<{ href?: string }>(({ href }) => ({
  cursor: href ? 'pointer' : undefined,
}));

interface CompanyLogoProps {
  subtitle?: string;
  size: keyof typeof SIZES;
  href?: string;
}

export const CompanyLogo: React.FC<CompanyLogoProps> = ({ subtitle, size, href }) => {
  const { width, height } = SIZES[size];
  const src = size === 'xsmall' ? '/logo-4Me.png' : '/outcomes4me_logo.png';

  // TODO: replace with ImageWithBasePath common component once piportal is upgraded to Next.js >= 13
  const logo = (
    <StyledImage
      src={`${process.env.NEXT_PUBLIC_BASE_PATH ?? ''}${src}`}
      width={width}
      height={height}
      alt="Outcomes4Me"
      href={href}
    />
  );

  if (href) {
    return (
      <NextLink href={href} passHref legacyBehavior>
        {subtitle ? (
          <StyledLink color="inherit" href={href}>
            {logo}
            <LogoSubtitle>{subtitle}</LogoSubtitle>
          </StyledLink>
        ) : (
          logo
        )}
      </NextLink>
    );
  }
  return (
    <LogoWrapper>
      {logo}
      {subtitle && <H1>{subtitle}</H1>}
    </LogoWrapper>
  );
};
