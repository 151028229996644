import { styled, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';

/**
 * @deprecated import { useTheme } from @mui/material/styles to apply typography styles
 */
export const textStyles = (
  theme: Theme,
): Record<
  'title' | 'logoSubtitle' | 'cardHead' | 'subHead' | 'caption' | 'justifier' | 'button' | 'tab',
  Record<string, string | number>
> => ({
  title: {
    fontSize: '2.2rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '2.4rem',
    letterSpacing: '0.015rem',
  },
  logoSubtitle: {
    fontSize: '1.2rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '1.7rem',
  },
  cardHead: {
    fontSize: '1.8rem',
    lineHeight: '2.4rem',
    fontWeight: 500,
    fontStyle: 'normal',
    color: theme.palette.text.primary,
  },
  subHead: {
    fontSize: '1.5rem',
    lineHeight: '2.0rem',
    fontWeight: 400,
    fontStyle: 'normal',
    color: theme.palette.text.secondary,
  },
  caption: {
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
    fontWeight: 400,
    fontStyle: 'normal',
    letterSpacing: '0.04rem',
    color: theme.palette.text.secondary,
  },
  justifier: {
    fontSize: '1.3rem',
    lineHeight: '1.6rem',
    fontWeight: 400,
    fontStyle: 'italic',
    letterSpacing: '0.04rem',
    color: theme.palette.text.secondary,
    marginTop: '14px',
  },
  button: {
    fontSize: '1.6rem',
    lineHeight: '1.6rem',
    fontWeight: 500,
    fontStyle: 'normal',
    letterSpacing: '0.125',
    color: theme.palette.text.disabled,
    textTransform: 'uppercase',
  },
  tab: {
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    fontWeight: 500,
    fontStyle: 'normal',
    letterSpacing: '0.04rem',
  },
});

interface TextProps {
  [propName: string]: unknown;
  className?: string;
  // types are borked :/ may be fixed by upgrading all admin NPM packages
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
}

const StyledTitle: FC<TextProps> = styled(Typography)(({ theme }) => textStyles(theme).title);

/**
 * @deprecated use common/src/components/typography/H5 instead
 */
export const Title: FC<TextProps> = (props) => (
  <StyledTitle variant="h1" component="h1" {...props} />
);

const StyledLogoSubtitle: FC<TextProps> = styled(Typography)(
  ({ theme }) => textStyles(theme).logoSubtitle,
);

/**
 * @deprecated use common/src/components/typography/Subtitle2 instead
 */
export const LogoSubtitle: FC<TextProps> = (props) => (
  <StyledLogoSubtitle variant="h1" component="div" {...props} />
);

const StyledCardHead: FC<TextProps> = styled(Typography)(({ theme }) => textStyles(theme).cardHead);

/**
 * @deprecated use common/src/components/typography/H6 instead
 */
export const CardHead: FC<TextProps> = (props) => (
  <StyledCardHead variant="h2" component="h2" {...props} />
);

const StyledSubHead: FC<TextProps> = styled(Typography)(({ theme }) => textStyles(theme).subHead);

/**
 * @deprecated use common/src/components/typography/Subtitle1 instead
 */
export const SubHead: FC<TextProps> = (props) => (
  <StyledSubHead variant="h3" component="h3" {...props} />
);

const StyledCaption: FC<TextProps> = styled(Typography)(({ theme }) => textStyles(theme).caption);

/**
 * @deprecated use common/src/components/typography/Caption instead
 */
export const Caption: FC<TextProps> = (props) => (
  <StyledCaption variant="body2" component="p" {...props} />
);

const StyledJustifier: FC<TextProps> = styled(Typography)(
  ({ theme }) => textStyles(theme).justifier,
);

/**
 * @deprecated use common/src/components/typography/Subtitle2 instead
 */
export const Justifier: FC<TextProps> = (props) => (
  <StyledJustifier variant="body2" component="p" {...props} />
);

const StyledButtonText: FC<TextProps> = styled(Typography)(({ theme }) => textStyles(theme).button);

/**
 @deprecated use common/src/components/typography/Overline instead
 */
export const ButtonText: FC<TextProps> = (props) => (
  <StyledButtonText variant="body2" component="p" {...props} />
);

const StyledTab: FC<TextProps> = styled(Typography)(({ theme }) => textStyles(theme).tab);

/**
 * @deprecated use common/src/components/typography/Subtitle1 instead
 */
export const Tab: FC<TextProps> = (props) => <StyledTab variant="body2" component="p" {...props} />;
