import { uniqueId } from 'lodash';

import { ApiResponseError } from './errors';
import { saveFile } from './files';
import { HttpRequestType, SignedInAuth } from './types';

export function getBaseApiUrl({
  serverSide,
  debug,
}: {
  serverSide: boolean;
  debug?: boolean;
}): string {
  const base =
    serverSide && process.env.NEXT_PUBLIC_SERVER_SIDE_OUTCOMES_API_URL
      ? process.env.NEXT_PUBLIC_SERVER_SIDE_OUTCOMES_API_URL
      : process.env.NEXT_PUBLIC_OUTCOMES_API_URL!;
  if (debug) {
    console.log('API: ' + base);
  }
  return base;
}

/**
 * Endpoints are not full URLs. They are partial url paths that map to API controllers.
 */
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export type Endpoint = `/${string}` | string;
// TECH DEBT: right now the Endpoint type is only for documentation.
// It can be used to enforce non-urls once we change all calls of the function.

type ApiOpts<B = string> = {
  auth?: SignedInAuth;
  /**
   * @default true
   *
   * Assumes response body is JSON data is with the format `{ "data": [important thing] }`
   * Set to false if you want to return the entire JSON body.
   */
  unwrapData?: boolean;
  /**
   * @default 'GET'
   */
  method?: string;
  /**
   * We assume you've stringified the request body into a string by default.
   * If set, 'content-type': 'application/json' will also be set in the header.
   *
   * Ignored if {@link ContentTypeHeaderOverrides} is set
   */
  body?: B;
  /**
   * If set, overrides the rules of {@link ApiOpts.body} (application/json).
   */
  contentTypeHeader?: ContentTypeHeaderOverrides['contentTypeHeader'];
  /**
   * If set, overrides the rules of {@link ApiOpts.body} (application/json).
   */
  acceptHeader?: ContentTypeHeaderOverrides['acceptHeader'];
  credentials?: RequestCredentials;
  rawData?: boolean;
  deviceCountry?: boolean;
  /**
   * @default true
   *
   * Includes 'x-api-key' header in request
   */
  apiKey?: boolean;
};

/**
 * @deprecated This should not need to be used.
 *
 * Please use one of the following instead:
 * * {@link getFromApi}
 * * {@link postToApi}
 * * {@link putToApi}
 * * {@link deleteFromApi}
 * * {@link uploadFileToApi}
 *
 */
export async function api<T, Body = string>(
  url: string,
  {
    auth,
    unwrapData,
    method,
    body,
    credentials,
    rawData,
    deviceCountry,
    apiKey = true,
    contentTypeHeader,
    acceptHeader,
  }: ApiOpts<Body> = {
    unwrapData: true,
    method: 'GET',
  },
): Promise<T> {
  const { locale } = Intl.DateTimeFormat().resolvedOptions();
  const response = await fetch(url, {
    ...(auth
      ? {
          headers: {
            ...(auth?.accessToken ? { 'x-cog-access-token': auth?.accessToken } : {}),
            ...(auth?.idToken ? { 'x-cog-id-token': auth?.idToken } : {}),
            ...(auth?.refreshToken ? { 'x-cog-refresh-token': auth?.refreshToken } : {}),
            ...(auth?.transientUserKey ? { 'x-transient-user-key': auth?.transientUserKey } : {}), // for temporarily generated "users"
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            ...getContentTypeHeaders(body, { contentTypeHeader, acceptHeader }),
            ...(auth?.transientUserKey ? { 'x-transient-user-key': auth?.transientUserKey } : {}),
            ...(deviceCountry ? { 'x-o4m-app-device-country': locale.split('-')[1] } : {}),
            ...(apiKey ? { 'x-api-key': process.env.NEXT_PUBLIC_OUTCOMES_API_KEY } : {}),
          },
        }
      : {
          headers: {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            ...getContentTypeHeaders(body, { contentTypeHeader, acceptHeader }),
          },
        }),
    method,
    body,
    credentials,
  } as RequestInit);
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  const data: unknown = rawData ? response : response.json();
  // TODO: confirm expected data response in a better way
  if (unwrapData) {
    return (data as { data: T }).data;
  }
  return data as T;
}

type ContentTypeHeaderOverrides<T extends string = 'application/json'> = {
  contentTypeHeader?: T;
  acceptHeader?: T;
};

/**
 * @see {@link api} function
 */
function getContentTypeHeaders(
  body: unknown,
  overrides: ContentTypeHeaderOverrides,
): {
  'content-type': ContentTypeHeaderOverrides['contentTypeHeader'];
  accept: ContentTypeHeaderOverrides['acceptHeader'];
};
function getContentTypeHeaders(
  body: unknown,
  overrides?: undefined,
): { 'content-type': 'application/json'; accept: 'application/json' };
function getContentTypeHeaders(body?: undefined, overrides?: undefined): Record<string, never>; // Record<string, never> is typescript for "{}"
function getContentTypeHeaders(
  body?: unknown,
  overrides?: ContentTypeHeaderOverrides,
): { ['content-type']?: string; accept?: string } {
  let toRet = {};
  if (body) {
    toRet = { 'content-type': 'application/json', accept: 'application/json' };
  }
  if (overrides?.acceptHeader || overrides?.contentTypeHeader) {
    toRet = {
      'content-type': overrides.contentTypeHeader ?? 'application/json',
      accept: overrides.acceptHeader ?? 'application/json',
    };
  }
  return toRet;
}

/**
 * We commonly report API errors that fail internal checks
 * with the {@link Response} as part of the cause.
 *
 * Used with {@link ApiResponseError}
 */
type ErrorWithApiResponse = Error & { cause: { response: Response } };

export const isErrorWithApiResponse = (e: unknown): e is ErrorWithApiResponse => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  return typeof e === 'object' && typeof (e as any)?.cause?.response === 'object';
};

/**
 * Attempts to retrieve an error message from the response data of an error. Assumes that the response has a message field in the json body.
 * If it can't do that, it will just return the overall error as a string.
 */
export const getApiErrorMessageFromError = async (e: ErrorWithApiResponse): Promise<string> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const body = await e.cause.response.json();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (typeof body.message === 'string') {
    return (body as { message: 'string ' }).message;
  }
  return `${e.toString()}`;
};

type CommonFetchOpts = {
  /**
   * Only server-side requests need the sessionCookie set
   * Used for authentication for serverSideProps.
   * If not passed, credentials:include is passed as a header instead.
   */
  sessionCookie?: string;
  /**
   * If true, we log aggressively.
   * Will print errors and successful responses to the browser.
   */
  debug?: boolean;
};

type CommonApiGetOpts = {
  /**
   * If defined and nonempty, then only the given status codes will be accepted as a valid response.
   * Otherwise response.ok will be used.
   *
   * An ok status is a status in the range 200 to 299, inclusive.
   * @see {@link https://fetch.spec.whatwg.org/#ok-status}
   */
  acceptedStatusCodes?: number[];
  /** If true, will attempt to use server side endpoint. */
  serverSide?: boolean;
  /** If true, return entire response object. Otherwise will assume json response and return just the json body. */
  rawData?: boolean;
} & CommonFetchOpts;

/**
 * Fetches from either the public url or the internal url (depending on whether this is a browser-side or server-side call)
 *
 * @param endpoint the path of the endpoint we are getting from (e.g. 'users' or '/users')
 * @returns response json or full response if options.rawData is true
 *
 * @throws ApiResponseError on bad response (either via response.ok or options.acceptedStatusCodes)
 *  including the entire response in the error.cause.response data.
 */
export async function getFromApi<T>(
  endpoint: Endpoint,
  { acceptedStatusCodes, sessionCookie, serverSide, rawData, debug }: CommonApiGetOpts = {},
): Promise<T> {
  const requestInit: RequestInit = {
    method: 'GET',
  };
  try {
    const base = getBaseApiUrl({ serverSide: serverSide || !!sessionCookie });
    if (sessionCookie) {
      requestInit.headers = { cookie: sessionCookie };
    } else {
      requestInit.credentials = 'include';
    }

    const path = endpoint.startsWith('/') ? endpoint.substring(1) : endpoint;
    const response = await fetch(`${base}/${path}`, requestInit);
    let responseAccepted = response.ok;
    if (acceptedStatusCodes && acceptedStatusCodes.length > 0) {
      responseAccepted = acceptedStatusCodes.includes(response.status);
    }
    if (!responseAccepted) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const json = await response.json();
      throw new ApiResponseError(
        `${response.status}: ${response.statusText} ${json ? JSON.stringify(json) : ''}`,
        { cause: { response } },
      );
    }
    const result = (await (rawData ? response : response.json())) as T;
    if (debug) {
      console.log(`${requestInit.method} ${endpoint}:`, result);
    }
    return result;
  } catch (e) {
    if (debug) {
      console.error(`${requestInit.method} ${endpoint}:`, e);
    }
    throw e;
  }
}

/**
 * @deprecated use {@link getFromApi} instead. It throws in exactly the same way by default.
 */
export async function getFromApiOrThrow<T>(
  endpoint: Endpoint,
  opts: CommonApiGetOpts = {},
): Promise<T> {
  console.warn('Deprecated, use getFromApi instead');
  return getFromApi(endpoint, opts);
}

type RawMutateApiRequestOpts = CommonFetchOpts & {
  /**
   * Use rawRequest if you need to send a non-JSON, non-stringified payload.
   * For example: Forms with file uploads.
   * If passed, requestType and payload will be ignored.
   * Accept headers must be included.
   * Auth Headers will still be automatically added.
   */
  rawRequest?: RequestInit;
};

/**
 * Assumes payload can be stringified to JSON. For non-JSON requests, use opts.rawRequest.
 * @deprecated will be merged with mutateApiRequest
 */
async function rawMutateApiRequest<B = Record<string, unknown>>(
  endpoint: Endpoint,
  payload: B,
  requestType: HttpRequestType,
  opts: RawMutateApiRequestOpts = {},
): Promise<Response> {
  const { rawRequest, sessionCookie, debug } = opts;
  try {
    const requestInit: RequestInit = rawRequest
      ? rawRequest
      : {
          method: requestType,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        };
    const base = getBaseApiUrl({ serverSide: typeof window === 'undefined' });
    // Only server-side requests need the sessionCookie set
    if (sessionCookie) {
      requestInit.headers = { ...requestInit.headers, cookie: sessionCookie };
    } else {
      requestInit.credentials = 'include';
    }
    const path = endpoint.startsWith('/') ? endpoint.substring(1) : endpoint;
    const result = await fetch(`${base}/${path}`, requestInit);
    if (debug) {
      console.log(`${requestInit.method} ${endpoint}:`, result);
    }
    return result;
  } catch (e) {
    if (debug) {
      console.error(`${requestType} ${endpoint}:`, e);
    }
    throw e;
  }
}

type MutateApiRequestOpts = RawMutateApiRequestOpts & {
  /**
   * Responses are parsed as JSON by default.
   * If true, the raw Response object will be passed back.
   */
  rawData?: boolean;
  /**
   * @default true
   *
   * If true, we check if the api response is response.ok and throw a default Error if it isn't.
   * Set this to false for manual error checking.
   */
  checkResponse?: boolean;
};

type JsonOnlyMutateApiRequestOpts = Omit<MutateApiRequestOpts, 'rawRequest'>;

/**
 * @deprecated will be merged with rawMutateApiRequest
 */
async function mutateApiRequest<T, B = Record<string, unknown>>(
  endpoint: Endpoint,
  payload: B,
  requestType: HttpRequestType,
  { rawData, checkResponse = true, ...rawOpts }: MutateApiRequestOpts = {},
): Promise<T> {
  const response = await rawMutateApiRequest(endpoint, payload, requestType, rawOpts);
  if (checkResponse && !response.ok) {
    // Note: ApiResponseError is not correctly handling the cause parameter so we're not using it right now.
    throw new Error(`${response.status}: ${response.statusText}`, {
      cause: { response },
    });
  }
  if (rawOpts.debug) {
    console.log('mutateApiRequest response', response);
    console.log({ rawData });
  }
  return rawData ? response : response.json();
}

/**
 * Saves a response from the api as a local file. Returns status of saved / cancelled / error.
 * @throws if the response object from the api cannot be converted to a {@link Blob}.
 */
export async function downloadFileFromApi(
  endpoint: Endpoint,
  { fileName }: { fileName?: string } = {},
): Promise<{ status: string }> {
  const result = await getFromApi(endpoint, { rawData: true });
  if (result && typeof result === 'object' && 'blob' in result) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
    const blob: Blob = await (result as any).blob();
    const suggestedName = fileName || uniqueId();
    const saveResult = await saveFile(blob, { suggestedName });
    return saveResult;
  } else {
    throw Error('Could not download file: invalid object downloaded');
  }
}

/**
 * Our file upload endpoints follow a common pattern that does not match our typical JSON request.
 *
 * Use {@link postToApi} to upload basic JSON.
 *
 * @param endpoint A File endpoint (e.g. '/admin/:disease/all')
 * @param file the file object to send
 * @param opts.additionalFields add these fields to include with the file upload.
 * Since we are returning the file as FormData, these must be string values.
 * @param opts.requestType defaults to PUT
 * @returns
 */
export function uploadFileToApi<T>(
  endpoint: Endpoint,
  file: File,
  opts: JsonOnlyMutateApiRequestOpts & {
    additionalFields?: Record<string, string>;
    requestType?: HttpRequestType.HTTP_PUT | HttpRequestType.HTTP_POST;
  } = {},
): Promise<T> {
  const formData = new FormData();
  formData.append('file', file);
  const { additionalFields, requestType = HttpRequestType.HTTP_PUT, ...otherOpts } = opts;
  if (additionalFields) {
    const keys = Object.keys(additionalFields);
    if (keys.includes('file')) {
      throw Error('"file" cannot be an additional field. It belongs to the file parameter.');
    }
    keys.forEach((key) => {
      formData.append(key, additionalFields[key]);
    });
  }
  return mutateApiRequest(endpoint, null, requestType, {
    ...otherOpts,
    rawRequest: {
      method: requestType,
      // we do not set headers here since that is determined by the FormData body.
      body: formData,
    },
  });
}

/**
 *
 * {@link see postFileToApi} if you need to upload a file. Do not stringify file input.
 *
 * @param endpoint
 * @param payload
 * @param opts
 * @returns
 */
export async function postToApi<T>(
  endpoint: Endpoint,
  payload: Record<string, unknown>,
  opts: JsonOnlyMutateApiRequestOpts = {},
): Promise<T> {
  return mutateApiRequest(endpoint, payload, HttpRequestType.HTTP_POST, opts);
}

export async function putToApi<T>(
  endpoint: Endpoint,
  payload: Record<string, unknown>,
  opts: JsonOnlyMutateApiRequestOpts = {},
): Promise<T> {
  return mutateApiRequest(endpoint, payload, HttpRequestType.HTTP_PUT, opts);
}

export async function deleteFromApi<T>(
  endpoint: Endpoint,
  opts: JsonOnlyMutateApiRequestOpts = {},
): Promise<T> {
  // empty payload ignored
  return mutateApiRequest(endpoint, {}, HttpRequestType.HTTP_DELETE, opts);
}
