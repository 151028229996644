/**
 * Used internally to create static errors with proper prototypes for
 * specific categories of error
 */
function CustomError(
  name: string,
  messageFunc?: (msg?: string) => string | undefined,
): ErrorConstructor {
  class ErrorDescendant extends Error {
    constructor(message?: string | undefined) {
      super(messageFunc ? messageFunc(message) : message);
      this.name = name;

      // Set the prototype explicitly. Necessary due to issues with how Typescript
      // extends builtins like Error.
      Object.setPrototypeOf(this, new.target.prototype);
    }
  }

  return ErrorDescendant as ErrorConstructor;
}

export const ApiResponseError = CustomError('ApiResponseError');
