import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import NextLink from 'next/link';
import React from 'react';

import { Caption } from '../typography/Caption';
import { Tab } from '../typography/legacy/Text';

const StyledA = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  fontSize: '1.6rem',
}));

interface CaptionLinkProps {
  href?: string;
  linkText: string;
  captionText?: string;
  onClick?: () => void;
}

export const CaptionLink: React.FC<CaptionLinkProps> = ({
  href,
  captionText,
  linkText,
  onClick,
}) => {
  if (onClick) {
    return (
      <Caption emphasis="medium">
        {captionText && captionText !== '' && `${captionText} `}
        <StyledA onClick={onClick}>{linkText}</StyledA>
      </Caption>
    );
  }
  return (
    <Caption emphasis="medium">
      {captionText && captionText !== '' && `${captionText} `}
      <NextLink href={href ?? ''} legacyBehavior>
        <StyledA>{linkText}</StyledA>
      </NextLink>
    </Caption>
  );
};

interface TabLinkProps {
  href: string;
  text: string;
}

export const TabLink: React.FC<TabLinkProps> = ({ href, text }) => {
  return (
    <Tab>
      <NextLink href={href} legacyBehavior>
        <StyledLink underline="hover">{text}</StyledLink>
      </NextLink>
    </Tab>
  );
};
