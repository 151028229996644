import Typography from '@mui/material/Typography';
import React from 'react';

import { EmphasisColorMap } from './typography.const';
import { TypographyProps } from './typography.types';

export const H1: React.FC<TypographyProps> = ({ className, emphasis = 'high', ...props }) => {
  return (
    <Typography variant="h1" {...props} className={className} color={EmphasisColorMap[emphasis]} />
  );
};
