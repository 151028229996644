import { FieldValues } from 'react-hook-form';

export interface SignedOutAuth {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any;
}

export interface SignedInAuth {
  accessToken?: string;
  idToken?: string;
  refreshToken?: string;
  transientUserKey?: string;
}

export type Auth = SignedInAuth | SignedOutAuth;

export enum HttpRequestType {
  HTTP_PUT = 'PUT',
  HTTP_POST = 'POST',
  HTTP_DELETE = 'DELETE',
}

export type UserLogin = FieldValues & {
  email: string;
  password: string;
};

export type UserRegistration = UserLogin & {
  /** accepted_terms will be deprecated in favor of accepted_latest_privacy_policy */
  accepted_terms?: boolean;
  accepted_latest_privacy_policy?: boolean;
  firstName: string;
  lastName: string;
  birthYear: number;
};

export const AUTH_METHODS = ['email', 'google', 'facebook', 'apple'];
export type AuthMethod = (typeof AUTH_METHODS)[number];

export function asAuthMethod(value: string | null): AuthMethod | undefined {
  if (value && AUTH_METHODS.includes(value)) return value;
  return undefined;
}
